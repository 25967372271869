:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f7fa;
  --text-color: #34495e;
  --border-color: #e0e6ed;
  --hover-color: #f0f3f7;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.app-header {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.user-name {
  font-size: 0.8rem;
  flex: 1;
  text-align: left;
}

.app-header h1 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  flex: 1;
  display: block;
  color: white;
}

.nav-links {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
}

.sign-out-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
}

.sign-in-button {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-in-button:hover {
  background-color: #357AE8;
}

@media (max-width: 600px) {
  .app-header {
    padding: 0.25rem 0.5rem;
  }

  .user-name {
    font-size: 0.7rem;
  }

  .app-header h1 {
    font-size: 1.2rem;
  }

  .nav-links a,
  .sign-out-button {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

button {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

form {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

input[type="datetime-local"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.datetime-input {
  position: relative;
  width: 30px;
  overflow: hidden;
}

.datetime-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.datetime-input::-webkit-datetime-edit {
  display: none;
}

.datetime-input::-webkit-clear-button {
  display: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

.task {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.3s ease;
}

.task:hover {
  background-color: var(--hover-color);
}

.task.completed {
  opacity: 0.6;
}

.task-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.task input[type="checkbox"] {
  margin-right: 15px;
}

.account-tag {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
}

.task-title {
  flex-grow: 1;
  margin-right: 10px;
}

.task.completed .task-title {
  text-decoration: line-through;
  color: #888;
}

.task-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.context-menu {
  position: fixed;
  background: #ffffff;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
}

.context-menu button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  color: var(--text-color);
}

.context-menu button:hover {
  background-color: var(--hover-color);
}

@media (max-width: 768px) {
  .App {
    padding: 20px 10px;
  }

  h1 {
    font-size: 1.8rem;
  }

  form {
    flex-direction: column;
  }

  form input,
  form button {
    width: 100%;
    margin-bottom: 10px;
  }

  .task {
    flex-wrap: wrap;
  }

  .task span:last-child {
    width: 100%;
    text-align: right;
    margin-top: 5px;
  }
}

.form-row {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.filter-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hover-color);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.filter-indicator button {
  background-color: var(--secondary-color);
  font-size: 0.8rem;
  padding: 5px 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.account-input-group {
  display: flex;
  gap: 10px;
  width: 100%;
}

.account-select,
.new-account-input {
  width: 50%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.task-input-group {
  display: flex;
  gap: 10px;
  width: 100%;
}

.task-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.date-button-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.datetime-input {
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 20px auto;
  cursor: pointer;
}

.datetime-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.add-task-button {
  white-space: nowrap;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .account-input-group {
    flex-direction: column;
  }
  
  .account-select,
  .new-account-input {
    width: 100%;
  }

  .date-button-group {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .add-task-button {
    width: auto;
  }
}

.task-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.task-date .timezone {
  font-size: 0.8em;
  color: #666;
}

.accounts {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.accounts form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.accounts ul {
  list-style-type: none;
  padding: 0;
}

.accounts li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.accounts li form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.accounts li button {
  padding: 5px 10px;
  font-size: 0.8rem;
}

.color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.accounts select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.color-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 30px 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 20px auto;
}

.color-select option {
  padding: 5px;
}

.color-select-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.small-color-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 0;
  cursor: pointer;
}

.color-picker-toggle.small-color-circle {
  display: inline-block;
  vertical-align: middle;
}

.color-swatch.small-color-circle {
  margin: 2px;
}

.color-picker {
  display: flex;
  /* gap: 5px; */
  flex-wrap: wrap;
  max-width: 200px;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.color-swatch:hover {
  transform: scale(1.1);
}

.color-swatch.selected {
  border: 2px solid #000;
}

.color-option {
  display: flex;
  align-items: center;
  padding: 5px;
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #ccc;
  display: inline-block;
}

/* Tailwind CSS classes */
.bg-white {
  background-color: #ffffff;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.text-sm {
  font-size: 0.875rem;
}

.text-gray-600 {
  color: #4a5568;
}

.text-2xl {
  font-size: 1.5rem;
}

.font-bold {
  font-weight: 700;
}

.text-blue-600 {
  color: #3182ce;
}

.hover\:text-blue-800:hover {
  color: #2b6cb0;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.text-white {
  color: #ffffff;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.rounded {
  border-radius: 0.25rem;
}

.hover\:bg-blue-600:hover {
  background-color: #3182ce;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 150ms;
}

.text-sm {
  font-size: 0.875rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

/* Ensure the header spans the full width and aligns correctly */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-right a,
.header-right span,
.header-right button {
  font-size: 0.875rem;
}

.header-right button {
  background-color: #4299e1;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.header-right button:hover {
  background-color: #3182ce;
}

.edit-task-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.full-width {
  width: 100%;
}

.edit-task-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.datetime-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23000000" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.save-button, .cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.save-button {
  background-color: var(--primary-color);
  color: white;
}

.cancel-button {
  background-color: var(--border-color);
  color: var(--text-color);
}

.header-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.landing-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.filter-container {
  margin-bottom: 20px;
}

.type-filter {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 1rem;
  width: 100%;
  max-width: 200px;
}

.toggle-archived-button {
  margin-bottom: 20px;
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-archived-button:hover {
  background-color: #27ae60;
}

.toggle-archived-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.toggle-archived-button.unarchived {
  background-color: #2ecc71; /* Green color */
}

.toggle-archived-button.archived {
  background-color: #e74c3c; /* Red color */
}

.toggle-archived-button:hover {
  opacity: 0.9;
}

.toggle-archived-button .material-icons {
  font-size: 24px;
}